import { NavLink } from "react-router-dom";
import { loadScript, publicUrlFor } from "../../../../globals/constants";
import { useEffect } from "react";
import {Link} from "react-scroll";
import { subDays, addDays } from "date-fns";

export default function SectionSlider1() {

    const today = new Date();
    const yesterday = subDays(today, 1); // ez a tegnapi dátumot adja vissza
    const tomorrow = addDays(today, 1); // ez a holnapi dátumot adja vissza

    const date = today.getDate(); // ez a mai napot adja vissza, pl. 6
    const yesterdayDate = yesterday.getDate(); // ez a tegnapi napot adja vissza, pl. 5
    const tomorrowDate = tomorrow.getDate(); // ez a holnapi napot adja vissza, pl. 7
    // TODO: If client wants slider
    useEffect(()=>{
        //loadScript('/assets/js/slider-home1.js', true);
        loadScript('/assets/js/bg-image-move.js', true);
    })

    return (
        <div className="twm-slider1-wrap">

            {/* Swiper */}
            <div className="swiper twm-slider">
                <div className="swiper-wrapper">
                    {/*Slide One */}

                    <div className="swiper-slide">

                        <div className="date-section">
                            <div>
                                {yesterdayDate < 10 ? `0${yesterdayDate}` : yesterdayDate}
                            </div>
                            <div className={'active'}>
                                {date < 10 ? `0${date}` : date}
                            </div>
                            <div>
                                {tomorrowDate < 10 ? `0${tomorrowDate}` : tomorrowDate}
                            </div>
                        </div>

                        <div className="h-banner-wrap">
                            <div className="h-banner-left">
                                <div className="h-bnr-top">
                                    <h2>
                                        <span className="title-outline">Költöztetés</span>
                                        stresszmentesen
                                    </h2>
                                    <p>Ha költözni szeretnél, <b>mi vagyunk a legjobb választás</b>. Mi nem csak a bútorokat és a dobozokat szállítjuk, hanem a nyugalmadat is. Mi <b>pontosan, óvatosan, és kedvesen</b> dolgozunk, hogy a költöztetés ne legyen terhes számodra.</p>

                                    <div className={'flex items-center'}>
                                        <Link
                                          activeClass="active"
                                          to="about-us"
                                          spy={true}
                                          smooth={true}
                                          offset={-70}
                                          duration={500}
                                          className="h-bnr-btn rounded-[5px] mr-[15px] cursor-pointer"
                                        >
                                            Érdekel
                                        </Link>
                                       {/* <NavLink to="#about-us" className="h-bnr-btn rounded-[5px] mr-[15px]">Érdekel</NavLink>*/}
                                        <a href="tel:06204343299" className="btn-half site-button phone-button mr-[20px]">
                                            <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                                        </a>
                                        {/*<a href="tel:06204344329" className="btn-half site-button phone-button phone-button-secondary" >
                                            <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                                        </a>*/}

                                    </div>
                                </div>

                            </div>
                            <div className="h-banner-right">
                                <div className="h-bnr-r-inner">
                                    <div className="h-bnr-media">
                                        <img src={publicUrlFor("/assets/images/main-slider/slider1/truck.png")} alt="#" className="slide-top" />
                                    </div>
                                    <div className="h-bnr-animation bg-cover bg-center bg-image-moving" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg2.jpg')})` }}>

                                    </div>
                                </div>
                            </div>
                            <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="circle-left-top zoon-in-out"></div>
                        </div>
                    </div>


                    <div className="swiper-slide">
                        <div className="h-banner-wrap">
                            <div className="h-banner-left">
                                <div className="h-bnr-top">
                                    <h2>
                                        <span className="title-outline">Around</span>
                                        The World
                                    </h2>
                                    <p>There are many variations of passages of worem Ipsum available, but the majority</p>
                                    <NavLink to="/services/services1" className="h-bnr-btn">View Services</NavLink>
                                </div>

                            </div>
                            <div className="h-banner-right">
                                <div className="h-bnr-r-inner">
                                    <div className="h-bnr-media">
                                        <img src={publicUrlFor("/assets/images/main-slider/slider1/plane.png")} alt="#" className="slide-top" />
                                    </div>
                                    <div className="h-bnr-animation bg-cover bg-center bg-image-moving" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg1.jpg')})` }}>

                                    </div>
                                </div>
                            </div>
                            <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="circle-left-top zoon-in-out"></div>
                        </div>
                    </div>

                    {/*Slide Two */}
                    <div className="swiper-slide">
                        <div className="h-banner-wrap">
                            <div className="h-banner-left">
                                <div className="h-bnr-top">
                                    <h2>
                                        <span className="title-outline">To Every2</span>
                                        Direction
                                    </h2>
                                    <p>There are many variations of passages of worem Ipsum available, but the majority</p>
                                    <NavLink to="/services/services1" className="h-bnr-btn">View Services</NavLink>
                                </div>

                            </div>
                            <div className="h-banner-right">
                                <div className="h-bnr-r-inner">
                                    <div className="h-bnr-media">
                                        <img src={publicUrlFor("/assets/images/main-slider/slider1/truck.png")} alt="#" className="slide-top" />
                                    </div>
                                    <div className="h-bnr-animation bg-cover bg-center bg-image-moving" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg2.jpg')})` }}>

                                    </div>
                                </div>
                            </div>
                            <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="circle-left-top zoon-in-out"></div>
                        </div>
                    </div>

                    {/*Slide Three */}
                    <div className="swiper-slide">
                        <div className="h-banner-wrap">
                            <div className="h-banner-left">
                                <div className="h-bnr-top">
                                    <h2>
                                        <span className="title-outline">Ready</span>
                                        To Move
                                    </h2>
                                    <p>There are many variations of passages of worem Ipsum available, but the majority</p>
                                    <NavLink to="/services/services1" className="h-bnr-btn">View Services</NavLink>
                                </div>

                            </div>
                            <div className="h-banner-right">
                                <div className="h-bnr-r-inner">
                                    <div className="h-bnr-media">
                                        <img src={publicUrlFor("/assets/images/main-slider/slider1/ship.png")} alt="#" className="slide-top" />
                                    </div>
                                    <div className="h-bnr-animation bg-cover bg-center bg-image-moving" style={{ backgroundImage: `url(${publicUrlFor('/assets/images/main-slider/slider1/bg3.jpg')})` }}>

                                    </div>
                                </div>
                            </div>
                            <div className="cross-line-box left up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="cross-line-box right up-down"><img src={publicUrlFor("/assets/images/main-slider/slider1/cross-line-box.png")} alt="#" /></div>
                            <div className="circle-left-top zoon-in-out"></div>
                        </div>
                    </div>

                </div>
                <div className="swiper-pagination"></div>
            </div>

        </div>
    )
}