import {NavLink} from "react-router-dom";
import {publicUrlFor} from "../../../../globals/constants";

export default function SectionWhyChooseUs1({_data}) {
  return (
    <div className="section-full-wrap site-bg-gray tw-why-choose-area">

      <div className="section-full p-t120 p-b120 tw-why-choose-area-top bg-cover bg-no-repeat"
           style={{backgroundImage: `url(${_data.background})`}}>
        <div className="container">

          <div className="tw-why-choose-section">

            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="tw-why-choose-left">
                  {/* <!-- TITLE START--> */}
                  <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                      <div>{_data.title}</div>
                    </div>
                    <h2 className="wt-title">{_data.subtitle}</h2>
                  </div>
                  {/* <!-- TITLE END--> */}
                  <strong>{_data.description.short}</strong>
                  <p className="section-head-text">

                    <ul className="max-w-md space-y-1 text-gray-500 list-inside dark:text-gray-400">
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Összeszokott, gyakorlott kollégákkal dolgozik
                      </li>
                      <li className="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Hiteles referenciákkal rendelkezik
                      </li>
                      <li class="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Garanciát vállal a munkára és a szállított tárgyakra egyaránt
                      </li>
                      <li class="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Rugalmas az időpontokat illetően, hétvégén és ünnepnapokon is elérhető
                      </li>
                      <li class="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Karbantartott, jó állapotú teherautókkal költöztet
                      </li>
                      <li class="flex items-center">
                        <svg className="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0"
                             aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                             viewBox="0 0 20 20">
                          <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                        </svg>
                        Megfizethetők az áraik
                      </li>
                    </ul>

                    <strong className={'mt-[25px]'}>Hirtelen jött lehetőség? Váratlan élethelyzet? Cserbenhagyták? Akár adott napon, órákon belül is találhatunk megoldást! Aktuális lehetőségeinkre kérdezzen rá bizalommal</strong>

                  </p>
                  <div className="tw-why-choose-left-bottom">

                    <a href="tel:06204343299" className="btn-half site-button phone-button white-bg mr-[10px]">
                      <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                    </a>
                   {/* <a href="tel:06204344329" className="btn-half site-button phone-button phone-button-secondary white-bg" >
                      <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                    </a>*/}
                  </div>
                </div>
              </div>

              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="tw-why-choose-right">
                  <div className="tw-why-choose-media1 shine-effect">
                    <div className="shine-box hidden md:block">
                      <img src={_data.pic1} alt="#"/>
                    </div>
                  </div>
                  <div className="tw-why-choose-media2 slide-top shine-effect">
                    <div className="shine-box">
                      <img src={_data.pic2} alt="#"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-hilite-text-wrap2">
              <div className="tw-hilite-text">
                <span>KÖLTÖZTETÉS</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tw-any-help-section">
        <div className="container">
          <div className="tw-any-help-inner">
            <img src={_data.help.icon} alt="#"/>
            <span className="tw-24">24/7 ELÉRHETŐSÉG</span>
            <span className="tw-help-title">Segítségre van szüksége?</span>
            <a href="tel:06204343299"><span className="tw-help-number">06 20 434 3299</span></a>
            {/*<a href="tel:06204344329"><span className="tw-help-number">06 20 434 4329</span></a>*/}
          </div>
        </div>
      </div>

    </div>
  )
}