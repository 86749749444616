import {NavLink} from "react-router-dom";

export default function SectionProjects1({_data}) {
  return (
    <div className="section-full  tw-project-1-wrap tw-project-1-wrap-bg site-bg-white" id="prices">
      {/* <!-- IMAGE CAROUSEL START --> */}
      <div className="section-content">

        <div className="container">
          <div className="tw-project-1-content">
            <div className="tw-project-1-content-position">
              {/* <!-- TITLE START--> */}
              <div className="section-head left wt-small-separator-outer">
                <div className="wt-small-separator site-text-primary">
                  <div>{_data.title}</div>
                </div>
                <h2>{_data.subtitle}</h2>
                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-[10px] text-teal-900 px-4 py-3 shadow-md mb-[20px] -mt-[20px]" role="alert">
                  <div className="flex items-center">
                    <div className="">
                      <svg className="fill-current h-[20px] w-[20px] text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg>
                    </div>
                    <div>
                      Budapesten belül <b>INGYENESEN</b> kimegyünk és felmérjük a költözést!
                    </div>
                  </div>
                </div>
                <span className={""}></span>
              </div>
              {/* <!-- TITLE END--> */}
              <div className="">


                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
                    <tr className={'border-b border-gray-200 dark:border-gray-800'}>
                      <th scope="col" className="px-6 py-3 bg-gray-60 dark:bg-gray-800">
                        Szolgáltatás
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Ár
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        1 sofőr + 1 rakodó
                      </th>
                      <td className="px-6 py-4">
                        7 990 Ft
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Kilóméter díj
                      </th>
                      <td className="px-6 py-4">
                        200 Ft / km
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Minden további rakodó
                      </th>
                      <td className="px-6 py-4">
                        3000 Ft / óra
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Doboz
                      </th>
                      <td className="px-6 py-4">
                        500 Ft / darab
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Légpárnás fólia
                      </th>
                      <td className="px-6 py-4">
                        400 Ft / méter
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Kiállási díj
                      </th>
                      <td className="px-6 py-4">
                        7 990 Ft
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Csomagoló fólia
                      </th>
                      <td className="px-6 py-4">
                        4 000 Ft
                      </td>
                    </tr>

                   {/* <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Távhordás
                      </th>
                      <td className="px-6 py-4">
                        100 Ft / karton
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Bútor távhordás
                      </th>
                      <td className="px-6 py-4">
                        500 / db
                      </td>
                    </tr>

                    <tr className="border-b border-gray-200 dark:border-gray-700">
                      <th scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                        Emelet pénz
                      </th>
                      <td className="px-6 py-4">
                        500 - 1 000 Ft / emelet
                      </td>
                    </tr>*/}

                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
}