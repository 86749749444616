import {NavLink} from "react-router-dom"
import {publicUrlFor} from "../../../globals/constants"
import {Link} from "react-scroll";

export default function Footer1() {
  return (
    <>
      <footer className="footer-dark">

        {/* NEWS LETTER SECTION START */}
        <div className="ftr-nw-ltr site-bg-white">

          <div className="ftr-nw-ltr-inner site-bg-primary">
            <div className="container">
              <div className="ftr-nw-img">
                <img src={publicUrlFor("/assets/images/news-l-bg.png")} alt="#"/>
              </div>
              <div className="ftr-nw-content">
                <div className="ftr-nw-title">
                  7/24 - Hívjon bizalommal!
                </div>
                <div className="ftr-nw-form">
                  <a href="tel:06204343299" className="ftr-nw-subcribe-btn mr-[10px] rounded-[5px]">06 20 434 3299</a>
                  {/*<a href="tel:06204344329" className="ftr-nw-subcribe-btn rounded-[5px]">06 20 4344 329</a>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* NEWS LETTER SECTION END */}

        <div className="ftr-bg">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">

                <div className="col-lg-4 col-md-6">

                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/">
                        {/* <img src={publicUrlFor("/assets/images/logo-dark.png")} alt="#" />*/}
                        <div className={"flex flex-column text-center"}>
                          <img className={'h-[40px] object-contain'} src={publicUrlFor("/assets/images/icons/truck.png")} alt=""/>
                          <div className={'text-[30px] text-bold leading-[28px] text-[#fefefe]'}>
                            TELEPORT
                          </div>
                          <div className={'text-[9px] bg-gradient-to-r from-[#efaf4d] to-[#eae244] bg-clip-text text-transparent'}>
                            Költöztetés - Bútorszállítás - TeherTaxi
                          </div>
                        </div>
                      </NavLink>
                    </div>
                    <p>KÖLTÖZTETÉS STRESSZMENTESEN
                    </p>
                    <ul className="social-icons">
                      <li><a target={'_blank'} href="https://www.facebook.com/profile.php?id=61555674404128" className="fa fa-facebook"> </a></li>
                    </ul>
                  </div>

                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Linkek</h3>
                    <ul>
                      <li>
                        <Link
                          activeClass="active"
                          to="about-us"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className={'cursor-pointer'}
                        >
                          Rólunk
                        </Link>
                      </li>

                      <li>

                        <Link
                          activeClass="active"
                          to="services"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className={'cursor-pointer'}
                        >
                          Szolgáltatásaink
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeClass="active"
                          to="prices"
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                          className={'cursor-pointer'}
                        >
                          Áraink
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Szolgáltatások</h3>
                    <ul>
                      <li><a>Költöztetés</a></li>
                      <li><a>Bútorszállítás</a></li>
                      <li><a>TeherTaxi</a></li>
                      <li><a>Áruszállítás</a></li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
          {/* FOOTER COPYRIGHT */}

          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">

                <div className="footer-copy-right">
                  <span className="copyrights-text">

                    <div className={'flex items-center justify-center'}>
                      <span>Copyright © 2024 by </span>
                      <a>
                        Teleport Költöztetés
                      </a>
                      <span>
                        All Rights Reserved
                      </span>
                    </div>

                  </span>
                </div>

              </div>
            </div>
          </div>
        </div>

      </footer>
    </>
  )
}