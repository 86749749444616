import {NavLink} from "react-router-dom";
import {publicUrlFor} from "../../../globals/constants";
import {useState} from "react";
import {Link, animateScroll as scroll} from "react-scroll";

function Header() {

  const [isActive, setIsActive] = useState(false);

  function toggleNavClass() {
    setIsActive(!isActive)
  }

  return (
    <header className={`site-header header-style-3 mobile-sider-drawer-menu ${isActive ? "active" : ""}`}>

      <div className="sticky-header main-bar-wraper navbar-expand-lg">
        <div className="main-bar">

          <div className="container-fluid clearfix">

            <div className="logo-header border-r-solid border-r-[#fefefe3d] md:border-r-[1px] px-[60px]">
              <div className="logo-header-inner logo-header-one">
                <NavLink to="#">
                  {/* <img src={publicUrlFor("/assets/images/logo-dark.png")} alt="#" />*/}
                  <div className={"flex flex-column text-center"}>
                    <img className={'h-[20px] md:h-[40px] object-contain '}
                         src={publicUrlFor("/assets/images/icons/truck.png")} alt=""/>
                    <div
                      className={'text-[18px] md:text-[30px] text-bold leading-[18px] md:leading-[28px] text-[#fefefe]'}>
                      TELEPORT
                    </div>
                    <div
                      className={'text-[6px] md:text-[9px] bg-gradient-to-r from-[#efaf4d] to-[#eae244] bg-clip-text text-transparent'}>
                      Költöztetés - Bútorszállítás - TeherTaxi
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* NAV Toggle Button */}
            <button id="mobile-side-drawer"
                    data-target=".header-nav"
                    data-toggle="collapse"
                    className="navbar-toggler collapsed"
                    onClick={toggleNavClass}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first"></span>
              <span className="icon-bar icon-bar-two"></span>
              <span className="icon-bar icon-bar-three"></span>
            </button>

            {/* MAIN Vav */}
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">

              <div className="nav navbar-nav">
                <li>
                  <Link
                    activeClass="active"
                    to="about-us"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={()=>{
                      setIsActive(false);
                    }}
                  >
                    Rólunk
                  </Link>
                </li>

                <li>

                  <Link
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={()=>{
                      setIsActive(false);
                    }}
                  >
                    Szolgáltatásaink
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="prices"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    onClick={()=>{
                      setIsActive(false);
                    }}
                  >
                    Áraink
                  </Link>
                </li>
              </div>

            </div>

            {/* Header Right Section */}
            <div className="extra-nav header-2-nav">
              {/* <div className="extra-cell">
                                <div className="header-search">
                                    <a href="#search" className="header-search-icon"><i className="fa fa-search"></i> </a>
                                </div>
                            </div>*/}
              <div className="extra-cell">
                <div className="header-nav-call-section">


                  <div className="detail">
                    <span className="title text-[#8c8aa3] text-[10px]">Hívjon minket most</span>
                    <span><a href="tel:06204343299">06 20 434 3299</a></span>
                    {/*<span className={'mt-[5px]'}><a href="tel:06204344329">06 20 434 4329</a></span>*/}

                  </div>
                  <div className="media">
                    <img src={publicUrlFor("/assets/images/call.png")} alt="#"/>
                  </div>
                </div>
              </div>

            </div>

          </div>


        </div>

        {/* SITE Search */}
        <div id="search">
          <span className="close"></span>
          <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
            <input className="form-control" name="q" type="search" placeholder="Type to search"/>
            <span className="input-group-append">
                            <button type="button" className="search-btn">
                                <i className="fa fa-paper-plane"></i>
                            </button>
                        </span>
          </form>
        </div>
      </div>

    </header>
  )
}

export default Header;