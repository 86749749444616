import { global } from "../constants";

export const data = {
  whatwedo: {
    title: "Rólunk",
    subtitle: "Költöztetők akikben megbízhat az ember",
    description: "Költöztetés Budapesten, vidéken és egész Európában, akár órákon belül megkezdjük a feladatteljesítést. Kedvező díjak, szakemberek által irányított és végrehajtott költöztetés, szállítás, tehertaxi, Valódi minőség, ár-érték arányunk pedig kimagasló. Egy tételt is szívesen elszállítunk, de elköltöztetünk egész lakásokat, házakat, irodaházakat is. Mindkét esetben kiváló szolgáltatás jár Ügyfeleinknek kedvező áron a megbízás nagyságától függetlenül. Jó hírünket sok-sok visszatérő Ügyfelünk erősíti, köszönjük!",
    image: global.icons + "img1.png",
    works: [
      {
        icon: global.icons + "pic1.png",
        serial: "01.",
        title: "Költöztetés",
        description: "Legyen budapesti vagy vidéki költöztetés, kollégáink már rutinos helyismerettel rendelkeznek."
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02.",
        title: "Bútorszállítás",
        description: "A bútorszállítás akkor hasznos, ha költözni szeretnél, vagy új bútort vásároltál, és el kell juttatnod az otthonodba. A bútorszállítók gondoskodnak a bútorok csomagolásáról, szereléséről, szállításáról, és kipakolásáról, így neked nem kell aggódnod a sérülés vagy a helyhiány miatt"
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03.",
        title: "TeherTaxi",
        description: "Ha egy vagy néhány tárgyat kell szállítanod, amit nem tudsz a személyautódban elhelyezni. A tehertaxi gyors és rugalmas megoldás, amit akár azonnal is igénybe vehetsz"
      },
      {
        icon: global.icons + "pic9.png",
        serial: "04.",
        title: "Áruszállítás",
        description: "Az ipari, kereskedelmi egységekben gyakran előfordul, hogy bizonyos termékeket el kell szállítani az egyik telephelyről a másikra, vagy az egymástól távol lévő raktárhelyiségek között kell mozgatni. Ilyenkor biztosítani kell minden feltételt ahhoz, hogy az áru biztonságosan elérjen a célállomásra a megadott időn belül."
      }
    ]
  },

  whychooseus: {
    background: global.back + "bg-1.jpg",
    title: "Miért minket válasszon?",
    subtitle: "Költöztetés mesterfokon",
    description: {
      short: "Az egyszerűtől a legbonyolultabb munkáig – ránk mindig, minden körülmények között számíthat!",
      long: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium dolorem que laudantium, totam rem aperiam, que ipsa quae ab illo inventore veritatis quasi architecto beatae itae dicta sunt explicabo. Nemo enim ipsam voluptate voluptas sitr atur aut odit aut fugit, sed quia consequuntur magni dolores is eos qui ratione voluptatem sequi nesciunt."
    },
    pic1: global.whyChoose + "pic22.jpeg",
    pic2: global.whyChoose + "pic2.jpg",
    help: {
      icon: global.whyChoose + "qt-icon.png",
      number: "(+36 30 111 2233)"
    }
  },

  booking: {
    background: global.booking + "bg-map.png",
    image: global.booking + "pic1.png",
    imageBack: "",
    title: "Request A Quote",
    subtitle: "Booking For Product Transformation",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    form: {
      quote: global.booking + "icon1.png",
      track: global.booking + "icon2.png"
    }
  },

  estimation: {
    background: global.back + "bg-2.jpg",
    title: "Gyors és egyszerű megoldás",
    subtitle: "A nap 24 órájában elérhetőek vagyunk, megfizethetők az áraink, nincsenek rejtett költségeink.",
    description: "Hívjon minket és a részletes információk alapján már személyre szabottan tudunk Önnek ajánlatot adni arra vonatkozólag, hogy a megrendelni kívánt költöztetés milyen költségekkel jár, milyen teendők szükségesek a költöztetést megelőzően, mibe, hogyan csomagoljon, mennyi időt vesz igénybe és milyen méretű teherautó szükséges.",
    solutions: [
      {
        serial: "01",
        title: "Hívjon minek!",
        subtitle: "7/24 elérhetőek vagyunk"
      },
      {
        serial: "02",
        title: "Kedvező árajánlat",
        subtitle: "Megfizethetők az áraink, nincsenek rejtett költségeink"
      },
      {
        serial: "03",
        title: "Megyünk és költöztetjük",
        subtitle: "Számunkra első a biztonság, ezért kizárólag kifogástalan műszaki állapotban lévő autókkal dolgozunk"
      }
    ]
  },

  testimonials: {
    title: "Respected",
    subtitle: "Clients & partners",
    clients: [
      global.logo + "/w1.png",
      global.logo + "/w2.png",
      global.logo + "/w3.png",
      global.logo + "/w4.png",
      global.logo + "/w5.png",
      global.logo + "/w6.png",
    ],
    partners: [
      {
        pic: global.testim + "pic1.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic2.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer"
      },
      {
        pic: global.testim + "pic3.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer"
      },
      {
        pic: global.testim + "pic4.jpg",
        quote: "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer"
      }
    ]
  },

  services: {
    title: "Szolgáltatásaink",
    subtitle: "Minden megbízást egyedi kihívásnak tekintünk és igény szerint a csomagolástól az összeszerelésig mindenben segítséget nyújtunk!",
    description: "Költöztetés teljes folyamata alatt munkánkra és az Ön értéktárgyaira szállítás közbeni garanciát vállalunk! Így Ön teljes biztonságban tudhatja minden vagyontárgyát!",
    services: [
      {
        image: global.sgallery + "1.jpg",
        title: "Költöztetés",
        serial: "01",
        description: "A költöztetés egy komplex szolgáltatás, amely a lakások, irodák, üzletek vagy egyéb helyiségek teljes vagy részleges átköltöztetését jelenti. A költöztetés magában foglalhatja a bútorok és a személyes tárgyak csomagolását, szállítását, rakodását, kirakodását, elhelyezését és kicsomagolását is"
      },
      {
        image: global.sgallery + "2.jpg",
        title: "Bútorszállítás",
        serial: "02",
        description: "A bútorszállítás során a bútorokat a megadott címről a kívánt helyre szállítják, akár lakásba, irodába, nyaralóba vagy raktárba. A bútorszállítás magában foglalhatja a bútorok csomagolását, szerelését, beállítását és védelmét is"
      },
      {
        image: global.sgallery + "3.jpg",
        title: "TeherTaxi",
        serial: "03",
        description: "A tehertaxi egy gyors és rugalmas szállítási megoldás, amely kisteherautóval vagy furgonnal végzi el a fuvarfeladatokat. A tehertaxi alkalmas kisebb áruk, csomagok, raklapok, háztartási gépek vagy egyéb tárgyak szállítására, akár azonnal is"
      },
      {
        image: global.sgallery + "4.jpg",
        title: "Áruszállítás",
        serial: "04",
        description: "Az áruszállítás egy olyan szolgáltatás, amely nagyobb mennyiségű vagy súlyú árukat szállít teherautóval vagy kamionnal. Az áruszállítás alkalmas a cégek, a beszerzők, a gyártók, a kereskedők vagy a magánszemélyek közötti áruáramlás biztosítására, akár országon belül, akár külföldre is"
      },
    ]
  },

  approach: {
    title: "Cégünkről",
    subtitle: "Legyen szó költözésről, bútorszállításról, tehertaxiről vagy áruszállításról",
    yearsOfExperience: "5",
    specifications: [
      {
        icon: global.icons + "c-pic1.png",
        count: 100,
        title: "Elégedett ügyfél"
      },
      {
        icon: global.icons + "c-pic2.png",
        count: 100,
        title: "Sikeres megbízás"
      },
      {
        icon: global.icons + "c-pic3.png",
        count: 2,
        title: "Teherautó"
      }
    ]
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog1 + "bg1.jpg",
        day: "02",
        month: "Aug",
        title: "At the end of the day, going forward, a new normal that has evolved from.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog2 + "l-1.jpg",
        day: "08",
        month: "Aug",
        title: "How all this mistaken idea denouncing pleasure and praising pain was.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      },
      {
        image: global.blog1 + "bg3.jpg",
        day: "21",
        month: "Aug",
        title: "Going forward, a new normal that has evolved from generation.",
        description: "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit."
      }
    ]
  },

  projects: {
    title: "Áraink",
    subtitle: "Pontos egyeztetés és felmérés után fix ár megyegyezés szerint ( az utólagos díjak elkerülése végett )",
    projects: [
      {
        image: global.projects + "1.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "2.jpg",
        title: "Logistics, Analytics",
        subtitle: "Minimize Manufacturing",
        caption: "Nh-16 Highway Bridge"
      },
      {
        image: global.projects + "3.jpg",
        title: "Warehousing, Distrbution",
        subtitle: "Warehouse inventory",
        caption: "Nh-16 Highway Bridge"
      }
    ]
  }
}
