import { useLocation } from "react-router-dom";

import Header from "../app/sections/common/header";
import Footer1 from "../app/sections/common/footer1";
import Footer2 from "../app/sections/common/footer2";
import AppRoutes from "../routing/app-routes";
import {publicUrlFor} from "../globals/constants";

export default function RootLayout() {
    const currentpath = useLocation().pathname;
    return (
        <>
            <div className="page-wraper">

                {
                    !currentpath?.startsWith('/error') &&
                    <Header />
                }

                {/* CONTENT START */}
                <div className="page-content">
                    <AppRoutes />
                </div>
                {/* CONTENT END */}

                {
                    !currentpath?.startsWith('/error') &&
                    (
                        ((currentpath === "/index2" || currentpath === "/index4") &&
                            <Footer2 />) || <Footer1 />
                    )
                }

                {/* BUTTON TOP START */}

              <button className="scroltop"><span className="fa fa-angle-up  relative" id="btn-vibrate"></span></button>


            {/*  <div className={'fast-links'}>
               <div className={'flex flex-column gap-[10px]'}>
                 <a href="tel:06204343299" className="btn-half site-button phone-button white-bg">
                   <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                 </a>
                 <a href="tel:06204344329" className="btn-half site-button phone-button phone-button-secondary white-bg" >
                   <img src={publicUrlFor("/assets/images/call.png")} alt="#" />
                 </a>
               </div>
              </div>*/}

            </div>
        </>
    )
}